import {
  initializeAuth,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { getFirebaseApp } from './firebase.helpers'

export function getFirebaseAuth() {
  try {
    return getAuth()
  } catch (error) {
    console.log('No pre-existing auth instance, initialising...')
  }
  const app = getFirebaseApp()
  if (app) {
    return initializeAuth(app)
  }
}

export async function loginWithGoogle() {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters({
    // Limit login to Collective Form Google Workspace members for now.
    hd: 'collectiveform.io',
  })
  const auth = getFirebaseAuth()
  if (auth) {
    try {
      // This function is actually async, so we want to relay that fact by making the calling function async as well.
      await signInWithPopup(auth, provider)
    } catch (error) {
      console.error('Error while attempting to sign in', error)
    }
  } else {
    console.log('No auth instance, unable to sign in')
  }
}

export async function logout() {
  const auth = getFirebaseAuth()
  if (auth) {
    await signOut(auth)
  } else console.log('No auth instance, unable to sign out')
}
