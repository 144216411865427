import { Typography, Stack } from '@mui/material'
import { AddCircle, PinDrop } from '@mui/icons-material'
import { useFirebaseContext } from '../../contexts/FirebaseContext'
import { useProjectContext } from '../../contexts/ProjectContext'
import { useIsMobile } from '../../hooks/useIsMobile'
import { createProject } from '../../helpers/firestore.helpers'
import MobileDashboardMap from '../DashboardMap/MobileDashboardMap'
import SidebarNavButton from './SidebarNavButton'
import InsiteLogo from './InsiteLogo'
import {
  AdminAnalyticsEventType,
  logAdminAnalyticsEvent,
} from '../../helpers/firebase-analytics.helpers'

export function ProjectList() {
  const { user } = useFirebaseContext()
  const { projects, reloadProjects, setActiveProject } = useProjectContext()
  const isMobile = useIsMobile()

  const handleNewProjectCreation = async () => {
    if (!user) {
      console.error('No valid authenticated user found')
      return
    }
    await createProject(user)
    reloadProjects()
    logAdminAnalyticsEvent(AdminAnalyticsEventType.ProjectCreation)
  }

  return (
    <Stack sx={{ my: '1rem' }}>
      <InsiteLogo />
      {isMobile && <MobileDashboardMap />}
      {projects.length > 0 ? (
        <Typography
          variant="h3"
          color="textSecondary"
          sx={{
            py: '0.25rem',
            px: '0.75rem',
            my: '0.25rem',
          }}
        >
          My Projects
        </Typography>
      ) : null}
      {projects.map((project, index) => (
        <SidebarNavButton
          key={`project-${index}`}
          onClick={() => setActiveProject(project)}
          label={project.info.name}
          startIcon={<PinDrop />}
        />
      ))}
      {user && (
        <SidebarNavButton
          label="Create new project"
          startIcon={<AddCircle />}
          onClick={handleNewProjectCreation}
        />
      )}
    </Stack>
  )
}
