import SettingsTextField from './SettingsTextField'
import SettingsSection from './SettingsSection'
import { useIsAdmin } from '../../hooks/useUserAccessLevel'

export default function ProjectSettingsForContent() {
  const userIsAdmin = useIsAdmin()
  return (
    /* TODO: make the actual title field above editable to solve for elegant renaming of the project */
    <SettingsSection>
      <SettingsTextField title="Name" formField="info.name" />
      <SettingsTextField title="Project type" formField="starterContent.type" />
      <SettingsTextField title="Location" formField="starterContent.location" />
      <SettingsTextField
        title="Description"
        formField="starterContent.description"
      />
      {userIsAdmin && (
        <>
          <SettingsTextField
            title="Feedback description"
            formField="starterContent.feedbackDescription"
          />
          <SettingsTextField
            title="Feedback email address"
            formField="starterContent.feedbackEmail"
          />
        </>
      )}
    </SettingsSection>
  )
}
