import {
  useFormContext,
  useFieldArray,
  FieldValues,
  Path,
  FieldArray,
} from 'react-hook-form'
import { Typography, TextField, Stack, Button, Box } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'
import { useIsAdmin } from '../../hooks/useUserAccessLevel'

type SettingsArrayProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
}

const useCheckedFormContext = () => {
  const formContext = useFormContext()
  if (!formContext) {
    handleFormContextUnavailableError('SettingsArray')
  }
  const { register, control } = formContext
  return { register, control }
}

export default function SettingsArray<TFieldValues extends FieldValues>({
  title,
  formField,
}: SettingsArrayProps<TFieldValues>) {
  const { register, control } = useCheckedFormContext()
  const { fields, append, remove } = useFieldArray({ control, name: formField })
  const userIsAdmin = useIsAdmin()
  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Stack spacing={4}>
        {fields && fields.length > 0 ? (
          fields.map((field, index) => (
            <Stack key={`${field.id}-stack`} direction="row" spacing={1}>
              <TextField
                {...register(`${formField}.${index}`)}
                multiline
                fullWidth
                sx={{ background: 'white', borderRadius: 1 }}
              />
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => remove(index)}
              >
                <Delete />
              </Button>
            </Stack>
          ))
        ) : (
          <TextField
            {...register(`${formField}`)}
            multiline
            fullWidth
            placeholder="your-project-name.insite.page"
            sx={{ background: 'white', borderRadius: 1 }}
          />
        )}
        {userIsAdmin && (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            onClick={() =>
              append('' as FieldArray<FieldValues, Path<TFieldValues>>)
            }
          >
            <Add />
          </Button>
        )}
      </Stack>
    </Box>
  )
}
