import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import SidebarNavButton from '../Sidebar/SidebarNavButton'

export default function DocumentationLinkButton() {
  return (
    <a
      href="https://collectiveform.notion.site/Insite-Documentation-External-cda2e8aa993b40c4ba9475f551d0be85?pvs=4"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SidebarNavButton label="Help & FAQ" startIcon={<HelpOutlineIcon />} />
    </a>
  )
}
