import { loginWithGoogle } from '../../helpers/firebase-auth.helpers'
import { Google } from '@mui/icons-material'
import SidebarNavButton from '../Sidebar/SidebarNavButton'

export default function LoginButton() {
  const handleLogin = async () => {
    if (loginWithGoogle) {
      await loginWithGoogle()
    }
  }

  return (
    <SidebarNavButton
      label="Sign in with Google"
      startIcon={<Google />}
      onClick={handleLogin}
    />
  )
}
