import { useState } from 'react'
import { MapProvider } from 'react-map-gl'
import { Box, Dialog } from '@mui/material'
import DashboardMap from './DashboardMap'
import { FullscreenToggleButton } from './FullscreenToggleButton'

export default function MobileDashboardMap() {
  const [isFullscreen, setIsFullscreen] = useState(false)

  return (
    <Box
      sx={{
        position: 'relative',
        width: 'auto',
        height: 170,
        transition: 'all 0.3s ease-in-out',
        boxSizing: 'border-box',
        borderRadius: 1,
        overflow: 'clip',
        mb: '2rem',
        mx: '0.75rem',
      }}
    >
      <FullscreenToggleButton
        variant={'open'}
        onClick={() => setIsFullscreen(!isFullscreen)}
      />

      <MapProvider>
        <DashboardMap />
      </MapProvider>

      <Dialog
        open={isFullscreen}
        onClose={() => setIsFullscreen(false)}
        fullScreen
      >
        <FullscreenToggleButton
          variant={'close'}
          onClick={() => setIsFullscreen(!isFullscreen)}
        />
        <MapProvider>
          <DashboardMap showControls />
        </MapProvider>
      </Dialog>
    </Box>
  )
}
