import { GeoPoint } from './GeoPoint'
import { GeoPoint as FirestoreGeoPoint, Timestamp } from 'firebase/firestore'

export const supportedUnitSystems = ['imperial', 'metric', 'nautical']
type UnitSystem = 'imperial' | 'metric' | 'nautical' | undefined

export type ProjectConfig = { id: string } & ProjectConfigData

export type ProjectConfigData = {
  info: ProjectInfo
  starterContent?: StarterContent
  mapboxSettings?: MapboxSettings
  mapViewSettings?: MapViewSettings
  publishingSettings?: PublishingSettings
  features?: string[]
}

export type ProjectInfo = {
  name: string
  createdAt: Timestamp
  // TODO: add project editing meta data here, last edit, last editor etc...
}

export type StarterContent = {
  type: string
  location: string
  description: string
  feedbackDescription?: string
  feedbackEmail?: string
}

export type MapboxSettings = {
  mapStyle: string
  mapConfig: {
    scrollZoom: boolean
    boxZoom: boolean
    dragRotate: boolean
    dragPan: boolean
    keyboard: boolean
    doubleClickZoom: boolean
    touchZoomRotate: boolean
    touchPitch: boolean
    minZoom: number
    maxZoom: number
    minPitch: number
    maxPitch: number
  }
}

// NOTE:  Allowing for both Firebase GeoPoints and the simplified GeoPoint datastructures is a bit
//        hacky, but feels like the most straight-forward way to allow for both Firebase and
//        react-hook-forms to work well. There probably room for some improvement here though
export type MapViewSettings = {
  initialUnitSystem: UnitSystem
  initialZoom: number
  initialCoordinates?: GeoPoint | FirestoreGeoPoint
  focusArea?: FocusArea
}

export type FocusArea = {
  southWestCorner?: GeoPoint | FirestoreGeoPoint
  northEastCorner?: GeoPoint | FirestoreGeoPoint
}

export type PublishingSettings = {
  hostnames: string[]
}
