export type InsiteUser = { id: string } & InsiteUserData

export type InsiteUserData = {
  email: string
  projects: UserProject[]
  accessLevel: UserAccessLevel
}

type UserProject = {
  id: string
  role: ProjectRole
}

export enum ProjectRole {
  owner = 'owner',
  collaborator = 'collaborator',
}

export enum UserAccessLevel {
  admin = 'admin',
  normal = 'normal',
}
