import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { getFirebaseAuth } from '../helpers/firebase-auth.helpers'
import { User, onAuthStateChanged } from 'firebase/auth'

type FirebaseContextContent = {
  user?: User
}
const FirebaseContext = createContext<FirebaseContextContent>({})

type FirebaseProviderProps = {
  children: ReactNode | ReactNode[]
}

export function FirebaseProvider({ children }: FirebaseProviderProps) {
  const [user, setUser] = useState<User | undefined>()

  useEffect(() => {
    const auth = getFirebaseAuth()
    if (auth) {
      // Assign an event handler to update the user object on auth state changes
      onAuthStateChanged(auth, (currentUser) => {
        currentUser ? setUser(currentUser) : setUser(undefined)
      })
    } else {
      console.log(
        'Attempted to configure auth state change handler but auth instance is undefined'
      )
    }
  }, [user])

  return (
    <FirebaseContext.Provider value={{ user }}>
      {children}
    </FirebaseContext.Provider>
  )
}

export function useFirebaseContext() {
  return useContext(FirebaseContext)
}
