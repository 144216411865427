import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'

type SimpleDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => Promise<void>
  title: string
  message: string
  confirmButtonText: string
}

export default function SimpleDialog({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmButtonText,
}: SimpleDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          onClick={onClose}
        >
          <Typography variant="body1">Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onConfirm}
          sx={{ borderRadius: '0.5rem' }}
        >
          <Typography variant="body1" color="white">
            {confirmButtonText}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
