import { FieldValues, Path, useController } from 'react-hook-form'
import { FormControlLabel, Switch, Typography } from '@mui/material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'

type SettingsSwitchProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
}

export default function SettingsSwitch<TFieldValues extends FieldValues>({
  title,
  formField,
}: SettingsSwitchProps<TFieldValues>) {
  const formController = useController<TFieldValues>({ name: formField })

  if (!formController) {
    handleFormContextUnavailableError('SettingsSwitch')
  }

  const { field } = formController
  return (
    <FormControlLabel
      control={<Switch {...field} checked={field.value} />}
      labelPlacement="start"
      label={<Typography variant="body2">{title}</Typography>}
      sx={{ justifyContent: 'space-between' }}
    />
  )
}
