import { ReactNode, createContext, useContext, useState } from 'react'
import { Feature } from '../helpers/mapbox.helpers'

type LocationContextContent = {
  mapLocation?: Feature
  setMapLocation: (location: Feature) => void
}

const LocationContext = createContext<LocationContextContent | undefined>(
  undefined
)

type LocationProviderProps = {
  children: ReactNode | ReactNode[]
}

export const LocationProvider = ({ children }: LocationProviderProps) => {
  const [mapLocation, setmapLocation] = useState<Feature | undefined>(undefined)

  const setMapLocation = (location: Feature) => {
    setmapLocation(location)
  }

  return (
    <LocationContext.Provider value={{ mapLocation, setMapLocation }}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocationContext() {
  const context = useContext(LocationContext)
  if (!context) {
    throw new Error('useLocation must be used within a LocationProvider')
  }
  return context
}
