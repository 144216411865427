import { Box } from '@mui/material'
import insiteLogo from '../../assets/images/insite-web-logo.svg'

export default function InsiteLogo() {
  return (
    <Box sx={{ px: '0.75rem', mb: '2rem', height: '40px' }}>
      <img
        src={insiteLogo}
        alt="Insite Logo"
        style={{ height: '24px', width: 'auto' }}
      />
    </Box>
  )
}
