import {
  FieldValues,
  Path,
  UseFormRegister,
  useFormContext,
} from 'react-hook-form'
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'
import { regexAnyDecimalNumber } from '../../regex'

type SettingsGeoPointProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
}
type CoordinateAxis = {
  name: string
  adornment: string
}

export default function SettingsGeoPoint<TFieldValues extends FieldValues>({
  title,
  formField,
}: SettingsGeoPointProps<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>()

  if (!formContext) {
    handleFormContextUnavailableError('SettingsGeoPoint')
  }

  const { register } = formContext

  const latitudeAxis = { name: 'latitude', adornment: '°N' }
  const longitudeAxis = { name: 'longitude', adornment: '°W' }

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <Stack spacing={4}>
        <CoordinateInput
          register={register}
          formField={formField}
          axis={latitudeAxis}
        />
        <CoordinateInput
          register={register}
          formField={formField}
          axis={longitudeAxis}
        />
      </Stack>
    </Box>
  )
}

type CoordinateInputProps<TFieldValues extends FieldValues> = {
  register: UseFormRegister<TFieldValues>
  formField: Path<TFieldValues>
  axis: CoordinateAxis
}
const CoordinateInput = <TFieldValues extends FieldValues>({
  register,
  formField,
  axis,
}: CoordinateInputProps<TFieldValues>) => {
  const title = axis.name.slice(0, 1).toUpperCase() + axis.name.slice(1)
  const axisFormField = `${formField}.${axis.name}` as Path<TFieldValues>
  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <TextField
        {...register(axisFormField)}
        inputProps={{
          inputMode: 'numeric',
          pattern: regexAnyDecimalNumber,
          step: 'any',
        }}
        fullWidth
        sx={{ background: 'white', borderRadius: 1 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{axis.adornment}</InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
