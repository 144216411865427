import SettingsSection from './SettingsSection'
import SettingsGeoPoint from './SettingsGeoPoint'
import SettingsMultipleChoice from './SettingsMultipleChoice'
import SettingsNumberField from './SettingsNumberField'
import { supportedUnitSystems } from '../../types/ProjectConfig'
import SettingsLocation from './SettingsLocation'

export default function ProjectSettingsForMapView() {
  return (
    <SettingsSection>
      <SettingsLocation title="Set project location" />
      <SettingsGeoPoint
        title="Initial map coordinates"
        formField="mapViewSettings.initialCoordinates"
      />
      <SettingsGeoPoint
        title="Focus area north east corner"
        formField="mapViewSettings.focusArea.northEastCorner"
      />
      <SettingsGeoPoint
        title="Focus area south west corner"
        formField="mapViewSettings.focusArea.southWestCorner"
      />
      <SettingsMultipleChoice
        title="Initial unit system"
        formField="mapViewSettings.initialUnitSystem"
        selectableValues={supportedUnitSystems}
      />
      <SettingsNumberField
        title="Initial zoom"
        formField="mapViewSettings.initialZoom"
      />
    </SettingsSection>
  )
}
