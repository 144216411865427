import { MapProvider } from 'react-map-gl'
import { Box } from '@mui/material'
import DashboardMap from './DashboardMap'

export default function DesktopDashboardMap() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
      }}
    >
      <MapProvider>
        <DashboardMap showControls />
      </MapProvider>
    </Box>
  )
}
