import { useEffect, useState } from 'react'
import { MapRef } from 'react-map-gl'
import Button from '@mui/material/Button'

type Toggle3DButtonProps = {
  map: MapRef | undefined
}

export function Toggle3DButton({ map }: Toggle3DButtonProps) {
  const [pitchLabel, setpitchLabel] = useState('3D')

  // Update button label on pitch change
  useEffect(() => {
    if (!map) return

    const handlePitchLabelChange = () => {
      setpitchLabel(map.getPitch() === 0 ? '3D' : '2D')
    }

    map.on('pitch', handlePitchLabelChange)

    return () => {
      map.off('pitch', handlePitchLabelChange)
    }
  }, [map])

  const toggleViewMode = () => {
    if (!map) return

    const newPitch = map.getPitch() === 0 ? 45 : 0
    map.easeTo({ pitch: newPitch })
  }

  return (
    <Button
      sx={{
        color: '#1a1a1a',
        top: '106px',
        right: '9px',
        position: 'absolute',
        backgroundColor: 'white',
        p: '0.25rem',
        minWidth: '32px',
        height: '32px',
        borderRadius: '50%',
        border: '2px solid #ddd',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      onClick={toggleViewMode}
    >
      {pitchLabel}
    </Button>
  )
}
