import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import '@fontsource/ibm-plex-sans'
import { Dashboard } from './pages/Dashboard'
import { FirebaseProvider } from './contexts/FirebaseContext'
import '@fontsource/ibm-plex-sans/500.css'
import theme from './theme'
import { LocationProvider } from './contexts/LocationContext'
import { ProjectProvider } from './contexts/ProjectContext'

function App() {
  return (
    <FirebaseProvider>
      <ThemeProvider theme={theme}>
        <ProjectProvider>
          <LocationProvider>
            <Dashboard />
          </LocationProvider>
        </ProjectProvider>
      </ThemeProvider>
    </FirebaseProvider>
  )
}

export default App
