import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useProjectContext } from '../../contexts/ProjectContext'
import { ArrowBack, MoreHoriz } from '@mui/icons-material'
import { useState } from 'react'
import SimpleDialog from '../Common/SimpleDialog'
import { useFirebaseContext } from '../../contexts/FirebaseContext'
import { deleteProject } from '../../helpers/firestore.helpers'

export default function ProjectSettingsHeader() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { getActiveProject, setActiveProject, reloadProjects } =
    useProjectContext()
  const project = getActiveProject()
  const { user } = useFirebaseContext()

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  const handleDialogOpen = () => {
    setDialogOpen(true)
    handleMenuClose()
  }

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const deselectProject = () => {
    setActiveProject(undefined)
  }

  const handleProjectDeletion = async () => {
    if (!user || !project) {
      console.error('No valid authenticated user or project found')
      return
    }
    await deleteProject(user, project.id)
    reloadProjects()
  }

  // TODO: In the unlikely case we that project is not set here, we actually propably want to show the back button.
  if (!project) {
    return null
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mt: '1rem', mb: '2rem', mx: '0.75rem' }}
    >
      <Stack direction="row" spacing="1rem" useFlexGap alignItems="center">
        <IconButton
          onClick={deselectProject}
          aria-label="back to project list"
          sx={{
            color: 'text.muted',
            '&:hover': {
              color: 'text.primary',
              backgroundColor: '#e5e5e5',
            },
          }}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h1">{project.info.name}</Typography>
      </Stack>

      <IconButton id="menu-button" onClick={handleMenuOpen}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={document.getElementById('menu-button')}
        open={menuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDialogOpen}>Delete project</MenuItem>
      </Menu>
      <SimpleDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleProjectDeletion}
        title={`Delete ${project.info.name}?`}
        message="Are you sure you want to delete this project? This action cannot be undone."
        confirmButtonText="Delete project"
      />
    </Stack>
  )
}
