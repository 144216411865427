import { createTheme } from '@mui/material/styles'
import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/500.css'
import '@fontsource/ibm-plex-sans/600.css'
import '@fontsource/ibm-plex-sans/700.css'
import '@fontsource/ibm-plex-sans/400-italic.css'
import '@fontsource/ibm-plex-sans/500-italic.css'
import '@fontsource/ibm-plex-sans/600-italic.css'
import '@fontsource/ibm-plex-sans/700-italic.css'

const textColors = {
  dark: 'rgba(0, 0, 0, 0.95)',
  muted: 'rgba(0, 0, 0, 0.5)',
  light: 'rgb(255,255,255)',
}

const theme = createTheme({
  typography: {
    fontFamily: 'IBM Plex Sans',
    h1: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: textColors.dark,
    },
    h2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.95)',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: 'rgba(0, 0, 0, 0.5)',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    // TODO : This works for now but it would  make a lot more sense to define custom button components instead
    buttonMedium: { fontSize: '1.25rem', fontWeight: 500, lineHeight: 1.235 },
  },
  shape: {
    borderRadius: 12,
  },
  spacing: 8,
  palette: {
    mode: 'light',
    contrastThreshold: 4.5,
    primary: {
      main: '#2F2AD5',
      contrastText: textColors.light,
    },
    secondary: {
      main: '#efefef',
      contrastText: textColors.dark,
    },
    background: {
      default: '#ffffff',
      paper: '#fafafa',
    },
  },
})

// -- Theme customisations --
declare module '@mui/material/styles' {
  interface TypographyVariants {
    buttonMedium: React.CSSProperties
  }

  // Allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    buttonMedium?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    buttonMedium: true
  }
}
// --

export default theme
