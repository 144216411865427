import React from 'react'
import { useFirebaseContext } from '../../contexts/FirebaseContext'
import LoginButton from './LoginButton'
import LogoutButton from './LogoutButton'
import DocumentationLinkButton from './DocumentationLinkButton'
import { Stack } from '@mui/material'
import FeedbackLinkButton from './FeedbackLinkButton'

export default function UserMenu() {
  const { user } = useFirebaseContext()

  return (
    <Stack sx={{ my: '1rem' }}>
      {user ? <LogoutButton /> : <LoginButton />}
      <DocumentationLinkButton />
      <FeedbackLinkButton />
    </Stack>
  )
}
