import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { TextField, Typography, Stack } from '@mui/material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'
import { regexAnyDecimalNumber } from '../../regex'

type SettingsNumberFieldProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
}

export default function SettingsNumberField<TFieldValues extends FieldValues>({
  title,
  formField,
}: SettingsNumberFieldProps<TFieldValues>) {
  const formContext = useFormContext()

  if (!formContext) {
    handleFormContextUnavailableError('SettingsNumberField')
  }

  const { register } = formContext
  return (
    <Stack direction="row" display="flex" justifyContent="space-between">
      <Typography variant="body2" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <TextField
        {...register(formField)}
        inputProps={{
          inputMode: 'numeric',
          pattern: regexAnyDecimalNumber,
          step: 'any',
        }}
        sx={{
          background: 'white',
          borderRadius: 1,
          width: '100px',
        }}
      />
    </Stack>
  )
}
