import { Button, CircularProgress, Typography } from '@mui/material'
import { useState } from 'react'

type SaveButtonProps = {
  isDirty: boolean
  onSubmit: () => Promise<void>
}

export default function SaveButton({ isDirty, onSubmit }: SaveButtonProps) {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await onSubmit()
    setIsLoading(false)
  }

  return (
    <Button
      type="submit"
      variant="contained"
      color={isDirty ? 'primary' : 'secondary'}
      fullWidth
      disableElevation
      onClick={handleClick}
      sx={{ p: 2, my: '1rem' }}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Typography variant="buttonMedium">Save</Typography>
      )}
    </Button>
  )
}
