import React from 'react'
import { useProjectContext } from '../../contexts/ProjectContext'
import ProjectMarker from './ProjectMarker'

export default function ProjectMapMarkers() {
  const { projects, setActiveProject } = useProjectContext()

  return (
    <>
      {projects.map((project) => {
        if (!project?.mapViewSettings?.initialCoordinates) {
          return null
        }

        const coords = project.mapViewSettings.initialCoordinates
        return (
          <ProjectMarker
            key={project.id}
            label={project.info.name}
            coords={coords}
            onClick={() => setActiveProject(project)}
          />
        )
      })}
    </>
  )
}
