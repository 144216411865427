import LogoutIcon from '@mui/icons-material/Logout'
import { logout } from '../../helpers/firebase-auth.helpers'
import SidebarNavButton from '../Sidebar/SidebarNavButton'

interface LogoutButtonProps {
  onLogout?: () => void
}

export default function LogoutButton({ onLogout }: LogoutButtonProps) {
  const handleLogout = async () => {
    if (logout) {
      await logout()
      onLogout && onLogout()
    }
  }

  return (
    <SidebarNavButton
      label="Logout"
      startIcon={<LogoutIcon />}
      onClick={handleLogout}
    />
  )
}
