import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ProjectConfig } from '../types/ProjectConfig'
import { useFirebaseContext } from './FirebaseContext'
import { getFirebaseApp } from '../helpers/firebase.helpers'
import { getProjects } from '../helpers/firestore.helpers'
import { User } from 'firebase/auth'

type ProjectContextContent = {
  projects: ProjectConfig[]
  reloadProjects: () => Promise<void>
  getActiveProject: () => ProjectConfig | undefined
  setActiveProject: (project?: ProjectConfig) => void
}

const ProjectContext = createContext<ProjectContextContent | undefined>(
  undefined
)

type ProjectProviderProps = {
  children: ReactNode | ReactNode[]
}

export const ProjectProvider = ({ children }: ProjectProviderProps) => {
  const [projects, setProjects] = useState<ProjectConfig[]>([])
  const [activeProjectId, setActiveProjectId] = useState<string | undefined>()

  const { user } = useFirebaseContext()
  const firebaseApp = getFirebaseApp()

  useEffect(() => {
    if (!firebaseApp) {
      setProjects([])
      console.log(
        'ERROR: Could not find Firebase instance when loading project configs'
      )
      return
    }
    fetchProjects(user)
  }, [firebaseApp, user])

  const fetchProjects = async (user?: User) => {
    if (user) {
      const loadedProjects = await getProjects(user)
      setProjects(loadedProjects)
    } else {
      setProjects([])
    }
  }

  const reloadProjects = async () => {
    await fetchProjects(user)
  }

  const setActiveProject = (project: ProjectConfig | undefined) => {
    const newActiveProjectId = project?.id ?? undefined
    setActiveProjectId(newActiveProjectId)
  }

  const getActiveProject = () => {
    if (!activeProjectId) {
      return undefined
    }
    return projects.find((project) => project.id === activeProjectId)
  }

  return (
    <ProjectContext.Provider
      value={{ projects, reloadProjects, getActiveProject, setActiveProject }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export function useProjectContext() {
  const context = useContext(ProjectContext)
  if (!context) {
    throw new Error(
      'useProjectContext hook must be used within a ProjectProvider context'
    )
  }
  return context
}
