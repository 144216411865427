import { Box, Stack, Typography } from '@mui/material'

type SettingsSectionProps = {
  title?: string
  children?: React.ReactNode
}

export default function SettingsSection({
  title,
  children,
}: SettingsSectionProps) {
  return (
    <Box sx={{ my: '1rem' }}>
      <Typography variant="h4" sx={{ my: '2rem' }}>
        {title}
      </Typography>
      <Stack direction="column" spacing="2rem">
        {children}
      </Stack>
    </Box>
  )
}
