import { CloseFullscreen, OpenInFull } from '@mui/icons-material'
import { IconButton } from '@mui/material'

type FullscreenToggleButtonProps = {
  variant: 'open' | 'close'
  onClick: () => void
}

export const FullscreenToggleButton = ({
  variant,
  onClick,
}: FullscreenToggleButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        position: 'absolute',
        top: '16px',
        left: '16px',
        zIndex: 2,
        backgroundColor: 'white',
      }}
    >
      {variant === 'open' ? <OpenInFull /> : <CloseFullscreen />}
    </IconButton>
  )
}
