import {
  collection,
  getDocs,
  setDoc,
  getFirestore,
  doc,
  query,
  where,
  getDoc,
  deleteField,
  serverTimestamp,
  Timestamp,
  QueryDocumentSnapshot,
  FirestoreDataConverter,
  GeoPoint as FirestoreGeoPoint,
  deleteDoc,
  connectFirestoreEmulator,
} from 'firebase/firestore'
import { User } from 'firebase/auth'
import { ProjectConfig, ProjectConfigData } from '../types/ProjectConfig'
import { GeoPoint } from '../types/GeoPoint'
import {
  InsiteUser,
  InsiteUserData,
  UserAccessLevel,
  ProjectRole,
} from '../types/InsiteUser'
import { getDefaultMapStyle } from './mapbox.helpers'
import { getFirebaseApp } from './firebase.helpers'

export async function getProjects(user: User): Promise<ProjectConfig[]> {
  const firestore = getFirestore()
  const collectionName = 'projects'
  const projectCollectionRef = collection(
    firestore,
    collectionName
  ).withConverter(insiteProjectConverter)

  const userProjectIds = await getUserProjectIds(user)
  if (!userProjectIds || userProjectIds.length < 1) {
    return []
  }

  const chunkArray = (arr: string[], size: number): string[][] =>
    arr.length > size
      ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)]
      : [arr]

  // The Firestore query limit is 30
  const querySize = 25
  const chunks = chunkArray(userProjectIds, querySize)

  const fetchProjectChunk = async (
    chunk: string[]
  ): Promise<ProjectConfig[]> => {
    try {
      const projectQuery = query(
        projectCollectionRef,
        where('__name__', 'in', chunk)
      )
      const querySnapshot = await getDocs(projectQuery)
      return querySnapshot.docs.map((doc) => doc.data() as ProjectConfig)
    } catch (error) {
      console.error('Error fetching projects:', error)
      // Return an empty array or handle the error as needed
      return []
    }
  }

  try {
    const projects = await Promise.all(chunks.map(fetchProjectChunk))
    const sortedProjects = projects.flat().sort(compareProjectCreationTimes)
    return sortedProjects
  } catch (error) {
    console.error('Error processing project chunks:', error)
    // Return an empty array or handle the error as needed
    return []
  }
}

function compareProjectCreationTimes(
  projectA: ProjectConfig,
  projectB: ProjectConfig
) {
  if (!projectA.info.createdAt || !projectB.info.createdAt) {
    return 0
  }
  return compareTimestamps(projectA.info.createdAt, projectB.info.createdAt)
}

function compareTimestamps(
  timestampA: Timestamp,
  timestampB: Timestamp
): number {
  const secondsDiff = timestampA.seconds - timestampB.seconds
  if (secondsDiff === 0) {
    return timestampA.nanoseconds - timestampB.nanoseconds
  }
  return secondsDiff
}

export async function writeProject(project: ProjectConfig) {
  const collectionName = 'projects'
  const firestore = getFirestore()
  const documentReference = doc(
    firestore,
    collectionName,
    project.id
  ).withConverter(insiteProjectConverter)
  const setOptions = { merge: true }

  await setDoc(documentReference, project, setOptions)
}

export async function createProject(user: User) {
  const firestore = getFirestore()
  const insiteUser = await getInsiteUser(user)
  if (!insiteUser) {
    throw new Error('Cannot find an user entry to link new project to')
  }
  const newProjectRef = doc(collection(firestore, 'projects'))
  await addProjectToInsiteUser(insiteUser, newProjectRef.id)

  const projectEntryNumber = insiteUser.projects.length + 1
  const minimumProjectConfig = {
    info: {
      name: `Project #${projectEntryNumber}`,
      createdAt: serverTimestamp(),
    },
    mapboxSettings: {
      mapStyle: getDefaultMapStyle(),
    },
  }

  const setOptions = { merge: true }
  await setDoc(newProjectRef, minimumProjectConfig, setOptions)
}

export async function deleteProject(user: User, projectId: string) {
  const firestore = getFirestore()
  const insiteUser = await getInsiteUser(user)
  if (!insiteUser) {
    throw new Error('Cannot find a user entry to remove project from')
  }
  const projectRef = doc(firestore, 'projects', projectId)
  await deleteDoc(projectRef)
  // Remove the project from the user's project array
  const updatedInsiteUser = {
    ...insiteUser,
    projects: insiteUser.projects.filter((project) => project.id !== projectId),
  }
  await setUser(updatedInsiteUser)
}

async function addProjectToInsiteUser(
  insiteUser: InsiteUser,
  projectId: string
) {
  const userProjectEntry = { id: projectId, role: ProjectRole.owner }
  const updatedInsiteUser = {
    ...insiteUser,
    projects: insiteUser.projects.concat([userProjectEntry]),
  }
  await setUser(updatedInsiteUser)
}

const insiteProjectConverter: FirestoreDataConverter<ProjectConfig> = {
  toFirestore: (item: ProjectConfig) => {
    const project = mapToFirestoreGeoPoints(item)
    return { ...project, id: deleteField() }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<ProjectConfigData>) => {
    const project = { id: snapshot.id, ...snapshot.data() } as ProjectConfig
    return mapFromFirestoreGeoPoints(project)
  },
}

async function getUserProjectIds(user: User): Promise<string[]> {
  const insiteUser = await getOrCreateInsiteUser(user)
  return insiteUser ? insiteUser.projects.map((project) => project.id) : []
}

async function getOrCreateInsiteUser(
  user: User
): Promise<InsiteUser | undefined> {
  const insiteUser = await getInsiteUser(user)
  if (insiteUser) {
    return insiteUser
  }
  return await createAndGetInsiteUser(user)
}

async function createAndGetInsiteUser(
  user: User
): Promise<InsiteUser | undefined> {
  createInsiteUser(user)
  return getInsiteUser(user)
}

export async function getInsiteUser(
  user: User
): Promise<InsiteUser | undefined> {
  const firestore = getFirestore()
  const documentSnapshot = await getDoc(
    doc(firestore, 'users', user.uid).withConverter(insiteUserConverter)
  )
  return documentSnapshot.data()
}

async function createInsiteUser(user: User) {
  const userDoc = {
    id: user.uid,
    email: user.email ?? '',
    projects: [],
    accessLevel: UserAccessLevel.normal,
  }
  await setUser(userDoc)
}

async function setUser(userDoc: InsiteUser) {
  const firestore = getFirestore()
  const collectionName = 'users'
  const documentReference = doc(
    firestore,
    collectionName,
    userDoc.id
  ).withConverter(insiteUserConverter)
  const setOptions = { merge: true }

  await setDoc(documentReference, userDoc, setOptions)
}

const insiteUserConverter: FirestoreDataConverter<InsiteUser> = {
  toFirestore: (item: InsiteUser) => {
    return { ...item, id: deleteField() }
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<InsiteUserData>) => {
    return { id: snapshot.id, ...snapshot.data() } as InsiteUser
  },
}

// NOTE:  Mapping to Firestore GeoPoints is not strictly necessary but makes for nicer viewing of
//        data in the Firebase console.
function mapToFirestoreGeoPoints(project: ProjectConfig) {
  return mapGeoPoints(project, convertToFirestoreGeoPoint)
}

function mapFromFirestoreGeoPoints(project: ProjectConfig) {
  return mapGeoPoints(project, convertFromFirestoreGeoPoint)
}

function mapGeoPoints(
  project: ProjectConfig,
  converter: (
    geopoint?: GeoPoint | FirestoreGeoPoint
  ) => GeoPoint | FirestoreGeoPoint | undefined
) {
  if (!project.mapViewSettings) {
    return project
  }
  const initialCoordinates = project.mapViewSettings.initialCoordinates
  const focusArea = project.mapViewSettings.focusArea
  return {
    ...project,
    mapViewSettings: {
      ...project.mapViewSettings,
      initialCoordinates: converter(initialCoordinates),
      focusArea: focusArea
        ? {
            southWestCorner: converter(focusArea.southWestCorner),
            northEastCorner: converter(focusArea.northEastCorner),
          }
        : focusArea,
    },
  }
}

const convertToFirestoreGeoPoint = (
  geopoint?: GeoPoint | FirestoreGeoPoint
): FirestoreGeoPoint | undefined => {
  if (!geopoint) {
    return undefined
  }
  // Firestore is pretty picky about not allowing falsy values for latitude or longitude
  return geopoint.latitude && geopoint.longitude
    ? new FirestoreGeoPoint(geopoint.latitude, geopoint.longitude)
    : undefined
}

const convertFromFirestoreGeoPoint = (
  geopoint?: GeoPoint | FirestoreGeoPoint
): GeoPoint | undefined => {
  if (!geopoint) {
    return undefined
  }
  // When reading fron Firestore we want to see all available data. Even incomplete coordinates
  return {
    longitude: geopoint.longitude,
    latitude: geopoint.latitude,
  }
}

if (window.location.hostname === 'localhost') {
  // Use the emulator for local development
  const app = getFirebaseApp()
  if (app) {
    const firestore = getFirestore(app)
    connectFirestoreEmulator(firestore, 'localhost', 8080)
  }
}
