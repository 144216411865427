import SettingsTextField from './SettingsTextField'
import SettingsSection from './SettingsSection'
import SettingsSwitch from './SettingsSwitch'
import SettingsNumberField from './SettingsNumberField'

export default function ProjectSettingsForMapbox() {
  return (
    <SettingsSection>
      <SettingsTextField
        title="Mapbox style URL"
        formField="mapboxSettings.mapStyle"
      />
      <SettingsNumberField
        title="Minimum zoom"
        formField="mapboxSettings.mapConfig.minZoom"
      />
      <SettingsNumberField
        title="Maximum zoom"
        formField="mapboxSettings.mapConfig.maxZoom"
      />
      <SettingsNumberField
        title="Minimum pitch"
        formField="mapboxSettings.mapConfig.minPitch"
      />
      <SettingsNumberField
        title="Maximum pitch"
        formField="mapboxSettings.mapConfig.maxPitch"
      />
      <SettingsSwitch
        title="Box zoom"
        formField="mapboxSettings.mapConfig.boxZoom"
      />
      <SettingsSwitch
        title="Double-click to zoom"
        formField="mapboxSettings.mapConfig.doubleClickZoom"
      />
      <SettingsSwitch
        title="Scroll to zoom"
        formField="mapboxSettings.mapConfig.scrollZoom"
      />
      <SettingsSwitch
        title="Zoom and rotate touch control"
        formField="mapboxSettings.mapConfig.touchZoomRotate"
      />
      <SettingsSwitch
        title="Pitch touch control"
        formField="mapboxSettings.mapConfig.touchPitch"
      />
      <SettingsSwitch
        title="Drag to pan"
        formField="mapboxSettings.mapConfig.dragPan"
      />
      <SettingsSwitch
        title="Drag to rotate"
        formField="mapboxSettings.mapConfig.dragRotate"
      />
      <SettingsSwitch
        title="Keyboard control"
        formField="mapboxSettings.mapConfig.keyboard"
      />
    </SettingsSection>
  )
}
