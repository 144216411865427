import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import { Feature } from '../../helpers/mapbox.helpers'

type LocationSuggestionsProps = {
  locationSuggestions: Feature[]
  onLocationSelection: (location: Feature) => void
}

export default function LocationSuggestions({
  locationSuggestions,
  onLocationSelection,
}: LocationSuggestionsProps) {
  const handleSuggestionClick = (suggestion: Feature) => {
    onLocationSelection(suggestion)
  }

  return (
    <Box>
      <List>
        {locationSuggestions.map((suggestion, index) => (
          <ListItemButton
            key={index}
            onClick={() => handleSuggestionClick(suggestion)}
          >
            <ListItemText primary={suggestion.place_name} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  )
}
