import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import { initializeFirestore } from 'firebase/firestore'

type FirebaseConfig = {
  apiKey: string | undefined
  authDomain: string | undefined
  projectId: string | undefined
  storageBucket: string | undefined
  messagingSenderId: string | undefined
  appId: string | undefined
  measurementId: string | undefined
}

// Get or initialise Firebase App instance
export function getFirebaseApp(): FirebaseApp | undefined {
  try {
    return getApp()
  } catch (error) {
    console.log('No pre-existing Firebase App instance, initialising...')
  }
  return initFirebase()
}

// Initialize Firebase
export function initFirebase(): FirebaseApp | undefined {
  const firebaseConfig = getFirebaseConfig()

  if (isFirebaseConfigured(firebaseConfig)) {
    const firebase = initializeApp(firebaseConfig)
    // initialise Firestore once here in order to reliably pass options that should have global effect
    // eslint-disable-next-line
    const firestore = initializeFirestore(firebase, {
      ignoreUndefinedProperties: true,
    })
    return firebase
  } else {
    console.log('Firebase not configured')
  }
}

function getFirebaseConfig(): FirebaseConfig {
  return {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_DASHBOARD_MEASUREMENT_ID,
  }
}

function isFirebaseConfigured(firebaseConfig?: FirebaseConfig) {
  const config = firebaseConfig ?? getFirebaseConfig()
  return (
    config.apiKey &&
    config.authDomain &&
    config.projectId &&
    config.storageBucket &&
    config.messagingSenderId &&
    config.appId &&
    config.measurementId
  )
}
