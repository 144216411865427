import SettingsSection from './SettingsSection'
import SettingsArray from './SettingsArray'

export default function ProjectSettingsForPublishing() {
  return (
    <SettingsSection>
      <SettingsArray
        title="Project URL"
        formField="publishingSettings.hostnames"
      />
    </SettingsSection>
  )
}
