import { Feature } from '../../helpers/mapbox.helpers'
import { useFormContext } from 'react-hook-form'
import LocationSearch from '../LocationSearch/LocationSearch'
import { Typography } from '@mui/material'

type SettingsLocationProps = {
  title?: string
}

export default function SettingsLocation({ title }: SettingsLocationProps) {
  const { setValue } = useFormContext()

  const updateFormFieldsOnLocationSelection = (location: Feature) => {
    // Extract the necessary data from the selected location
    const [longitude, latitude] = location.center

    // Update the initial coordinates form field
    setValue(
      'mapViewSettings.initialCoordinates',
      { longitude, latitude },
      { shouldDirty: true }
    )

    // Update the location name form field
    setValue('starterContent.location', location.place_name, {
      shouldDirty: true,
    })

    // If the location has a bounding box, update the focus area form fields
    if (location.bbox) {
      const [west, south, east, north] = location.bbox
      setValue(
        'mapViewSettings.focusArea.northEastCorner',
        {
          longitude: east,
          latitude: north,
        },
        { shouldDirty: true }
      )
      setValue(
        'mapViewSettings.focusArea.southWestCorner',
        {
          longitude: west,
          latitude: south,
        },
        { shouldDirty: true }
      )
    }
  }

  return (
    <>
      <Typography variant="body1">{title}</Typography>
      <LocationSearch
        onLocationSelection={updateFormFieldsOnLocationSelection}
        variant="inline"
      />
    </>
  )
}
