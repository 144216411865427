import SidebarNavButton from '../Sidebar/SidebarNavButton'
import { AddComment } from '@mui/icons-material'

export default function FeedbackLinkButton() {
  return (
    <a
      href="https://airtable.com/appvOvrr3OwP5Ervr/shrPOTNQP3fBSPlPs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <SidebarNavButton label="Give us feedback" startIcon={<AddComment />} />
    </a>
  )
}
