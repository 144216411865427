import { useEffect, useState } from 'react'
import { UserAccessLevel } from '../types/InsiteUser'
import { useFirebaseContext } from '../contexts/FirebaseContext'
import { getInsiteUser } from '../helpers/firestore.helpers'

export function useUserAccessLevel() {
  const [userAccessLevel, setUserAccessLevel] = useState<UserAccessLevel>()
  const { user } = useFirebaseContext()

  useEffect(() => {
    async function fetchUserAccessLevel() {
      if (user) {
        const insiteUser = await getInsiteUser(user)
        setUserAccessLevel(insiteUser?.accessLevel ?? UserAccessLevel.normal)
      }
    }

    fetchUserAccessLevel()
  }, [user])

  return userAccessLevel
}

export const useIsAdmin = () => {
  const userAccessLevel = useUserAccessLevel()
  return userAccessLevel === UserAccessLevel.admin
}
