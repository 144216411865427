import { Box } from '@mui/material'
import { Feature } from '../../helpers/mapbox.helpers'
import LocationSearchInput from './LocationSearchInput'

type LocationSearchProps = {
  onLocationSelection?: (location: Feature) => void
  variant: 'inline' | 'map'
}

export default function LocationSearch({
  onLocationSelection,
  variant,
}: LocationSearchProps) {
  const width = variant === 'inline' ? '100%' : 300

  return (
    <Box
      sx={{
        position: 'relative',
        float: 'right',
        marginTop: 1,
        marginRight: 7,
        zIndex: 1,
        background: 'white',
        borderRadius: 1,
        width,
      }}
    >
      <LocationSearchInput onLocationSelection={onLocationSelection} />
    </Box>
  )
}
