import { Sidebar } from '../components/Sidebar/Sidebar'
import { Stack } from '@mui/material'
import { useIsMobile } from '../hooks/useIsMobile'
import DesktopDashboardMap from '../components/DashboardMap/DesktopDashboardMap'

export function Dashboard() {
  const isMobile = useIsMobile()

  return (
    <Stack direction="row">
      <Sidebar />
      {!isMobile && <DesktopDashboardMap />}
    </Stack>
  )
}
