import { ChangeEvent, useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Tabs, Tab, Box } from '@mui/material'
import { ProjectConfig } from '../../types/ProjectConfig'
import { useProjectContext } from '../../contexts/ProjectContext'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useIsAdmin } from '../../hooks/useUserAccessLevel'
import { writeProject } from '../../helpers/firestore.helpers'
import ProjectSettingsForContent from './ProjectSettingsForContent'
import ProjectSettingsForMapbox from './ProjectSettingsForMapbox'
import ProjectSettingsForMapView from './ProjectSettingsForMapView'
import ProjectSettingsForPublishing from './ProjectSettingsForPublishing'
import MobileDashboardMap from '../DashboardMap/MobileDashboardMap'
import ProjectSettingsHeader from './ProjectSettingsHeader'
import SaveButton from './SaveButton'

export default function ProjectSettings() {
  const [activeTab, setActiveTab] = useState(0)
  const isMobile = useIsMobile()
  const { getActiveProject, reloadProjects } = useProjectContext()

  const project = getActiveProject()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [project])
  const userIsAdmin = useIsAdmin()

  const formFunctions = useForm<ProjectConfig>({ defaultValues: project })
  const { handleSubmit, formState, reset } = formFunctions

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue)
  }

  const onSubmit = handleSubmit(async (projectFormData) => {
    await writeProject(projectFormData)
    reset(undefined, {
      keepValues: true,
      keepDirty: false,
      keepDefaultValues: false,
    })
    // Reload all projects to reflect changes outside the form context
    await reloadProjects()
  })

  return (
    <>
      <ProjectSettingsHeader />
      {isMobile && <MobileDashboardMap />}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="scrollable"
        sx={{ mx: '0.75rem' }}
      >
        <Tab label="Content" />
        <Tab label="Map View" />
        <Tab label="Publishing" />
        {userIsAdmin && <Tab label="Mapbox" />}
      </Tabs>
      <Box margin="1rem 0.75rem">
        <FormProvider {...formFunctions}>
          <form onSubmit={onSubmit}>
            {activeTab === 0 && <ProjectSettingsForContent />}
            {activeTab === 1 && <ProjectSettingsForMapView />}
            {activeTab === 2 && <ProjectSettingsForPublishing />}
            {activeTab === 3 && userIsAdmin && <ProjectSettingsForMapbox />}
            <SaveButton isDirty={formState.isDirty} onSubmit={onSubmit} />
          </form>
        </FormProvider>
      </Box>
    </>
  )
}
