import {
  Controller,
  FieldValues,
  Path,
  PathValue,
  useFormContext,
} from 'react-hook-form'
import { Box, MenuItem, Select, Typography } from '@mui/material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'

type SettingsMultipleChoiceProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
  selectableValues: string[]
}

export default function SettingsMultipleChoice<
  TFieldValues extends FieldValues
>({
  title,
  formField,
  selectableValues,
}: SettingsMultipleChoiceProps<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>()

  // This is a bit of a hack to prevent MUI logging warnings about using values out of range for the Select component
  const getDefaultValue = () => {
    return selectableValues.length > 0 ? selectableValues[0] : undefined
  }

  if (!formContext) {
    handleFormContextUnavailableError('SettingsMultipleChoice')
  }

  const { control } = formContext
  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <Controller
        name={formField}
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            onChange={(newValue) =>
              field.onChange(
                newValue as PathValue<TFieldValues, Path<TFieldValues>>
              )
            }
            value={field.value ?? getDefaultValue()}
            fullWidth
            sx={{ background: 'white', borderRadius: 1 }}
          >
            {selectableValues.map((selectableValue) => (
              <MenuItem
                key={`menu-item-${selectableValue}`}
                value={selectableValue}
              >
                {selectableValue}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Box>
  )
}
