import { Drawer, Box } from '@mui/material'
import { useProjectContext } from '../../contexts/ProjectContext'
import { useIsMobile } from '../../hooks/useIsMobile'
import { ProjectList } from './ProjectList'
import ProjectSettings from '../ProjectSettings/ProjectSettings'
import UserMenu from '../UserMenu/UserMenu'

// TODO: Expressing the drawer width as a percentage with a maxWidth and minWidth in pixels is likely to scale better
const drawerWidth = 460

export function Sidebar() {
  const { getActiveProject } = useProjectContext()
  const isMobile = useIsMobile()
  const project = getActiveProject()

  const desktopDrawerStyling = {
    width: drawerWidth,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
    },
  }

  const mobileDrawerStyling = {
    // Set full screen width for mobile devices
    width: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  }

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open
      sx={{
        flexShrink: 0,
        ...desktopDrawerStyling,
        ...(isMobile && { ...mobileDrawerStyling }),
      }}
    >
      <Box m="1rem">
        {project ? (
          <ProjectSettings />
        ) : (
          <>
            <ProjectList />
            <UserMenu />
          </>
        )}
      </Box>
    </Drawer>
  )
}
