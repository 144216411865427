import { getFirebaseApp } from './firebase.helpers'
import { getAnalytics, logEvent } from 'firebase/analytics'

export enum AdminAnalyticsEventType {
  ProjectCreation = 'project_creation',
}

export function logAdminAnalyticsEvent(
  eventType: AdminAnalyticsEventType,
  eventParams?: { [key: string]: any }
) {
  const firebaseApp = getFirebaseApp()

  if (firebaseApp) {
    const analytics = getAnalytics(firebaseApp)

    const amendedEventParams = {
      ...eventParams,
      timestamp: new Date().toISOString(),
    }

    logEvent(analytics, eventType, amendedEventParams)
  } else {
    console.log('Unable to log analytics event, Firebase app not initialized')
  }
}
