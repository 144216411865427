import { Button } from '@mui/material'

type SidebarNavButtonProps = {
  label: string
  onClick?: () => void
  endIcon?: React.ReactNode
  startIcon?: React.ReactNode
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
  variant?: 'text' | 'outlined' | 'contained'
}

export default function SidebarNavButton({
  onClick,
  label,
  endIcon,
  startIcon,
  color,
  variant,
}: SidebarNavButtonProps) {
  return (
    <Button
      onClick={onClick}
      variant={variant || 'text'}
      color={color || 'secondary'}
      disableElevation
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth
      sx={{
        py: '0.25rem',
        px: '0.75rem',
        my: '0.25rem',
        gap: '0.5rem',
        borderRadius: '0.25rem',
        justifyContent: 'flex-start',
        textTransform: 'none',
        textAlign: 'left',
        color: 'text.primary',
        '&:hover': {
          backgroundColor: '#e5e5e5',
        },
      }}
    >
      {label}
    </Button>
  )
}
