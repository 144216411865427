import { Button, Typography } from '@mui/material'
import PlaceIcon from '@mui/icons-material/Place'
import { GeoPoint } from '../../types/GeoPoint'
import { Marker } from 'react-map-gl'
import { useIsMobile } from '../../hooks/useIsMobile'

type ProjectMarkerProps = {
  label: string
  coords: GeoPoint
  onClick: () => void
}

export default function ProjectMarker({
  label,
  coords,
  onClick,
}: ProjectMarkerProps) {
  const isMobile = useIsMobile()
  return (
    <Marker longitude={coords.longitude} latitude={coords.latitude}>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: 'white',
          padding: '0.4rem',
          borderRadius: '0.375rem',
          gap: '0.3rem',
          opacity: 0.9,
          minWidth: 0, // TODO: This is needed to ensure the project marker shrinks to fit the map icon when the label is not visible. Not sure why, but we should figure it out.
          '&:hover': {
            opacity: 1,
            background: 'white',
          },
        }}
        onClick={onClick}
      >
        {!isMobile && <Typography variant="body1">{label}</Typography>}
        <PlaceIcon sx={{ height: '1.25rem' }} />
      </Button>
    </Marker>
  )
}
