import React, { useState } from 'react'
import { IconButton, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Feature, getLocationSuggestions } from '../../helpers/mapbox.helpers'
import LocationSuggestions from './LocationSuggestions'
import { useLocationContext } from '../../contexts/LocationContext'

type LocationSearchInputProps = {
  onLocationSelection?: (location: Feature) => void
}

export default function LocationSearchInput({
  onLocationSelection,
}: LocationSearchInputProps) {
  const [locationSuggestions, setSuggestions] = useState<Feature[]>([])
  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
    updateLocationSuggestions(value)
  }

  const updateLocationSuggestions = async (value: string) => {
    if (value.length > 2) {
      // Only fetch suggestions if input length is greater than 2
      const fetchedSuggestions = await getLocationSuggestions(value)
      setSuggestions(fetchedSuggestions)
    } else {
      setSuggestions([]) // Clear suggestions if input is too short
    }
  }

  const { setMapLocation } = useLocationContext()

  const handleLocationSelection = (location: Feature) => {
    clearInput()
    setMapLocation(location) // Update global map location state
    if (onLocationSelection) {
      onLocationSelection(location)
    }
  }

  const clearInput = () => {
    setSuggestions([])
    setInputValue('')
  }

  const hasInputValue = () => inputValue.length > 0
  const hasLocationSelections = () => locationSuggestions.length > 0

  return (
    <>
      <TextField
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Search for a location..."
        fullWidth
        InputProps={{
          endAdornment: (
            <>
              {hasInputValue() ? (
                <IconButton onClick={() => clearInput()}>
                  <ClearIcon />
                </IconButton>
              ) : (
                <SearchIcon />
              )}
            </>
          ),
        }}
      />
      {hasLocationSelections() && (
        <LocationSuggestions
          locationSuggestions={locationSuggestions}
          onLocationSelection={handleLocationSelection}
        />
      )}
    </>
  )
}
