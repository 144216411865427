import { ProjectConfig } from '../types/ProjectConfig'
import { LngLatBounds } from 'mapbox-gl'

export function getMapboxToken() {
  return process.env.REACT_APP_MAPBOX_TOKEN
}

export function getDefaultMapStyle() {
  return 'mapbox://styles/collectiveform/cljrmoeg3007d01rd5dkre53o'
}

export type Feature = {
  place_name: string
  center: number[]
  id: string
  bbox: number[]
}

export async function getLocationSuggestions(
  locationString: string
): Promise<Feature[]> {
  const response = await fetchLocationSuggestions(locationString)

  if (!response) {
    console.warn('No response from API')
    return []
  }

  const data = await response.json()

  if (data.features) {
    return data.features as Feature[]
  } else {
    console.warn('No location suggestions found for:', locationString)
    return []
  }
}

async function fetchLocationSuggestions(
  locationString: string
): Promise<Response | undefined> {
  const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
    locationString
  )}.json?access_token=${getMapboxToken()}`

  try {
    return await fetch(endpoint)
  } catch (error) {
    console.error('Error fetching location suggestions:', error)
  }
}

export function getBoundingBox(projects: ProjectConfig[]) {
  const bounds = new LngLatBounds()

  if (projects.length > 0) {
    projects.forEach((project) => {
      if (project?.mapViewSettings?.initialCoordinates) {
        const coords = project.mapViewSettings.initialCoordinates
        bounds.extend([coords.longitude, coords.latitude])
      }
    })
  }

  return bounds
}

// Example usage:
// console.log(fetchLocationSuggestions('Tokyo'))
