import { FieldValues, Path, useFormContext } from 'react-hook-form'
import { Box, TextField, Typography } from '@mui/material'
import { handleFormContextUnavailableError } from '../../helpers/error.helpers'

type SettingsTextFieldProps<TFieldValues extends FieldValues> = {
  title: string
  formField: Path<TFieldValues>
}

export default function SettingsTextField<TFieldValues extends FieldValues>({
  title,
  formField,
}: SettingsTextFieldProps<TFieldValues>) {
  const formContext = useFormContext<TFieldValues>()

  if (!formContext) {
    handleFormContextUnavailableError('SettingsTextField')
  }

  const { register } = formContext
  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {title}
      </Typography>
      <TextField
        {...register(formField)}
        multiline
        fullWidth
        sx={{ background: 'white', borderRadius: 1 }}
      />
    </Box>
  )
}
