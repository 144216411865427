import { useEffect } from 'react'
import { Box } from '@mui/material'
import Map, {
  NavigationControl,
  ScaleControl,
  MapRef,
  useMap,
} from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { FocusArea, ProjectConfig } from '../../types/ProjectConfig'
import { GeoPoint } from '../../types/GeoPoint'
import {
  getMapboxToken,
  getBoundingBox,
  getDefaultMapStyle,
} from '../../helpers/mapbox.helpers'
import LocationSearch from '../LocationSearch/LocationSearch'
import ProjectMapMarkers from '../ProjectMapMarkers/ProjectMapMarkers'
import { useLocationContext } from '../../contexts/LocationContext'
import { useProjectContext } from '../../contexts/ProjectContext'
import { useIsMobile } from '../../hooks/useIsMobile'
import { Toggle3DButton } from './Toggle3DButton'

// The following is required to stop "npm build" from transpiling mapbox code.
import mapboxgl from 'mapbox-gl'
// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

type DashboardMapProps = {
  showControls?: boolean
}

type FocusPoint = {
  longitude: number
  latitude: number
  zoom: number
}

const fallbackFocusPoint: FocusPoint = {
  // North America (center of the continent)
  longitude: -97.86381607883351,
  latitude: 39.51872591053325,
  zoom: 3,
}

const getFallbackCoordinates = (): GeoPoint => {
  return {
    latitude: fallbackFocusPoint.latitude,
    longitude: fallbackFocusPoint.longitude,
  }
}

export default function DashboardMap({ showControls }: DashboardMapProps) {
  const { insitemap } = useMap()
  const { mapLocation } = useLocationContext()
  const { projects, getActiveProject } = useProjectContext()
  const project = getActiveProject()
  const isMobile = useIsMobile()

  const mapStyleUrl = project?.mapboxSettings?.mapStyle || getDefaultMapStyle()

  useEffect(() => {
    if (!mapLocation || !insitemap) {
      return
    }

    if (mapLocation.bbox) {
      const [west, south, east, north] = mapLocation.bbox
      insitemap.fitBounds(
        [
          [west, south],
          [east, north],
        ],
        {
          padding: 20,
        }
      )
    } else if (mapLocation.center) {
      const [longitude, latitude] = mapLocation.center
      insitemap.flyTo({ center: [longitude, latitude] })
    }
  }, [mapLocation, insitemap])

  useEffect(() => {
    if (!insitemap) {
      return
    }
    if (project?.mapViewSettings?.focusArea) {
      flyToFocusArea(insitemap, project.mapViewSettings.focusArea)
    } else if (project?.mapViewSettings?.initialCoordinates) {
      flyToCoordinates(
        insitemap,
        project.mapViewSettings.initialCoordinates,
        project.mapViewSettings.initialZoom
      )
    } else if (projects && projects.length > 0) {
      flyToAllProjectsView(insitemap, projects, isMobile)
    } else {
      flyToFallbackCoordinates(insitemap)
    }
  }, [insitemap, project, projects, isMobile])

  const flyToFallbackCoordinates = (map: MapRef) => {
    map.flyTo({
      center: [fallbackFocusPoint.longitude, fallbackFocusPoint.latitude],
      zoom: fallbackFocusPoint.zoom,
    })
  }

  const flyToFocusArea = (map: MapRef, focusArea: FocusArea) => {
    const sw = focusArea.southWestCorner
    const ne = focusArea.northEastCorner
    if (sw && ne) {
      map.fitBounds([
        [sw.longitude, sw.latitude],
        [ne.longitude, ne.latitude],
      ])
    }
  }

  const flyToCoordinates = (
    map: MapRef,
    coordinates: GeoPoint,
    zoom?: number
  ) => {
    const { longitude, latitude } = coordinates
    const zoomLevel = zoom ?? fallbackFocusPoint.zoom
    map.flyTo({ center: [longitude, latitude], zoom: zoomLevel })
  }

  const flyToAllProjectsView = (
    map: MapRef,
    projects: ProjectConfig[],
    isMobile: boolean
  ) => {
    // Filter out projects without valid coordinates
    const projectsWithCoordinates = projects.filter(
      (project) =>
        project.mapViewSettings && project.mapViewSettings.initialCoordinates
    )

    // Proceed only if there are valid projects
    if (projectsWithCoordinates.length > 0) {
      // TODO: this is a hack to make sure that the map is zoomed out enough to see all projects on mobile
      // This value is important because it determines how much padding is added to the map bounds
      // Too much padding will cause the app to crash at certain window sizes
      const boundingBox = getBoundingBox(projectsWithCoordinates)
      const boundsPaddingValue = isMobile ? 60 : 110
      map.fitBounds(boundingBox, {
        padding: boundsPaddingValue,
        // The maxZoom ensures the map doesn't zoom in too far when there's only one project
        maxZoom: 10,
      })
    }
  }

  const getInitialViewState = () => {
    if (!project?.mapViewSettings) {
      return fallbackFocusPoint
    }

    const coordinates =
      project.mapViewSettings.initialCoordinates ?? getFallbackCoordinates()
    const zoom = project.mapViewSettings.initialZoom ?? fallbackFocusPoint.zoom
    return {
      ...coordinates,
      zoom: zoom,
    }
  }

  // Only show the on-map location search box if no project is selected, but never on mobile
  const showLocationSearch = !project && !isMobile
  // Only show selectable project markers on the map if no project is already selected
  const showProjectMapMarkers = !project && projects
  // TODO: set a sane default unit system based on locale or something, rather than hardcoding it if no project config is available
  const scaleUnit = project?.mapViewSettings?.initialUnitSystem ?? 'imperial'

  return (
    <Box sx={{ flex: 1, width: '100%', height: '100%' }}>
      <Map
        id="insitemap"
        mapboxAccessToken={getMapboxToken()}
        initialViewState={getInitialViewState()}
        mapStyle={mapStyleUrl}
        reuseMaps
      >
        {showLocationSearch && <LocationSearch variant="map" />}
        {showControls && <NavigationControl />}
        {showControls && <ScaleControl unit={scaleUnit} />}
        {showControls && <Toggle3DButton map={insitemap} />}
        {showProjectMapMarkers && <ProjectMapMarkers />}
      </Map>
    </Box>
  )
}
